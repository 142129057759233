









































































import { AuthLoginFieldError, ErrorManager } from '@/models';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
export default Vue.extend({
  name: 'ProfileChangeNamePage',

  data: () => ({
    showPassword: false,
    first_name: '',
    last_name: '',
    errorMessage: '',
    loading: false,

    rules: {
      required: (value: string) => !!value || 'Required.'
    },

    fieldError: new AuthLoginFieldError()
  }),

  computed: {
    ...mapGetters(['getThemeMode']),
    ...mapGetters({ user: 'auth/getUser' }),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  mounted() {
    this.first_name = this.user.first_name;
    this.last_name = this.user.last_name;
  },

  methods: {
    ...mapActions({
      updateUser: 'auth/updateUser'
    }),

    clearAllErrors(): void {
      this.fieldError.clearErrors();
      this.errorMessage = '';
    },

    submitForm() {
      this.clearAllErrors();

      this.loading = true;

      this.updateUser({
        first_name: this.first_name,
        last_name: this.last_name
      })
        .then(() => {
          this.$router.push({ name: 'account-profile' });
        })
        .catch((error) => {
          if (error.response) {
            // client received an error response that falls out of range 2xx
            if (AuthLoginFieldError.isLoginFieldError(error.response.data)) {
              this.fieldError = new AuthLoginFieldError(error.response.data);
            }
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => (this.loading = false));
    }
  }
});
